/* Ticket Assignments Grid */
.ticket-assignments-grid {
  width: 100%;
}

/* Ticket Assignments Card */
.order-details {
  max-width: 400px;
  width: 35% !important;
}

.MuiCardHeader-title {
  color: #d50032;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.section-title {
  color: #d11f34;
  padding: 20px 0px;
}

.recommended-show {
  padding: 0px 0px 10px 5px;
}

.ticket-assignments-table {
  width: 50%;
}

/* Ticket Assignments Inputs */
.ticket-assignments .MuiFormControl-root {
  width: 100% !important;
  min-width: 200px;
}

.restaurant-inputs {
  padding: 20px 10px;
}

.ticket-assignments-inputs {
  padding: 0px 0px;
  width: 100%;
  flex-direction: row !important;
}

.ticket-assignments-input-wrapper {
  margin-left: 0px !important;
  padding: 10px 10px;
  max-width: 220px;
}

.ticket-assignments .MuiInputBase-input {
  height: 56px !important;
}

.ticket-assignments .MuiPagination-root {
  justify-content: flex-end;
  padding: 40px 0px;
  display: flex;
}

.ticket-assignments .pagination-top {
  margin-top: -10px;
}

.ticket-assignments .search-stack {
  margin-top: 30px;
  margin-bottom: -80px;
}

.ticket-assignments .filter-section {
  max-width: 670px;
}

.ticket-assignments .MuiInputBase-input.MuiSelect-select {
  height: 0px !important;
}

.tickets-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* Dropzone */
.dropzone-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropzone-section .ticket-assignments-input-wrapper.dropzone {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 250px;
  height: 250px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin: 10px 20px;
}

.dropzone-section .ticket-assignments-input-wrapper.dropzone div,
.dropzone-section .ticket-assignments-input-wrapper.dropzone section {
  min-height: 200px;
  width: 100%;
}

.dropzone-section .ticket-assignments-input-wrapper.dropzone div:hover {
  cursor: pointer;
}

.dropzone-section .ticket-assignments-input-wrapper.dropzone img {
  margin: 0px auto;
  width: 100%;
}

.ticket-assignments .button {
  margin: 10px !important;
  display: block;
  width: 80%;
}

.date-picker-stack,
.order-details-section {
  flex-direction: row !important;
}

.show-selection-section {
  width: 70%;
  padding-left: 30px;
  margin: 0 !important;
}

@media (max-width: 800px) {
  .ticket-assignments-table {
    width: 30%;
  }

  .order-details {
    width: 100% !important;
  }

  .dropzone-section {
    margin: 0 !important;
    width: 100%;
  }

  .ticket-assignments-inputs {
    width: 200% !important;
  }

  .ticket-assignments .MuiFormControl-root{
    width: 100% !important;
  }

  .ticket-assignments .MuiPagination-root {
    justify-content: center;
    padding: 40px 0px;
    display: flex;
  }

  .ticket-assignments .pagination-top {
    margin-top: 0;
  }

  .ticket-assignments .search-stack {
    margin-bottom: -50px;
  }

  .order-details {
    min-width: 0px;
  }

  .show-selection-section {
    width: 100%;
    padding-left: 10px;
  }

  .tickets-buttons,
  .ticket-assignments .button {
    width: 100%;
  }

  .date-picker-stack,
  .ticket-assignments-inputs,
  .order-details-section {
    flex-direction: column !important;
  }

  .css-17vdz66,
  .date-picker-stack {
    width: 100%;
  }

  .show-date-picker {
    margin: 15px 0px !important;
    margin-left: 0px !important;
    width: 100%;
  }
}
