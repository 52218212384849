.app-content {
  background-color: white;
}

.header {
  background-color: #271c21;
}

.header-nav {
  width: 100%;
  margin: 10px 20px;
}

.header-nav .title {
  padding-left: 20px;
  color: white;
  font-family: Roboto;
  text-align: left;
  width: 100%;
}

.black-button {
  background-color: black !important;
  color: white !important;
}

.top-padding {
  padding: 10px 0px;
}

.no-margin {
  margin: 0px 0px 0px 0px !important;
  width: 100%;
}

.radio-wrapper {
  font-weight: 400;
}

.hamburger .MuiSvgIcon-root {
  color: white;
}

.MuiDrawer-paperAnchorDockedLeft {
  background-color: #271c21 !important;
}

.MuiDrawer-paperAnchorDockedLeft span {
  color: white;
}

.MuiFab-root {
  background-color: rgba(0, 0, 0, 0) !important;
}

hr.MuiDivider-root {
  background-color: white;
  opacity: 0.5;
}