.configuration-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0px auto;
  width: 60%;
}

.configuration-content.align-left {
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 150px;
}

.show-quota {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  width: 100%;
}

.show-quota .show-name,
.show-quota .show-tickets {
  width: 50%;
}

.quotas-description {
  margin: 0px auto 40px;
}

.notification-times {
  margin: 0px auto;
  width: 110%;
}

.notification-input {
  display: inline-block;
  margin: 5px;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: initial !important;
  }
}

