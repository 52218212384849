/* Universal Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

h1 {
  font-family: Roboto;
  color: #271c21;
}

h2 {
  font-family: Roboto;
}

h3 {
  font-family: Roboto;
  color: #d11f34;
  font-weight: 500;
}

p {
  font-family: Roboto;
}

a:active, a:hover {
  text-decoration: underline;
}

a {
  color: #d50032;
  text-decoration: none;
}

.background-gray {
  background-color: #FFFAEE;
}

.f11 {
  font-size: 11px;
}

.w95 {
  width: 95%;
}

.app-content {
  min-height: 100vh;
}

.input-wrapper {
  padding: 15px 0px;
}

.br-black {
  color: #271c21;
}

.padding {
  padding: 15px 0px;
  margin: 0px auto;
}

.small-text {
  font-size: 12px;
  font-weight: 200;
}

/* Mobile and Desktop Rendering */
.stack.showmobile,
.css-e53awj-MuiStack-root.showmobile,
.showmobile {
  display: none;
}

.hidemobile {
  display: block;
}

.stack.hidemobile,
.css-e53awj-MuiStack-root.hidemobile {
  display: flex;
}

.page-title {
  color: #d11f34;
  padding: 10px 0px;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 20%) !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root.test-order {
  box-shadow: 0px 0px 8px 1px #d11f34 !important;
}

/* List tables */
.table-title {
  color: #d11f34;
  padding: 0px;
}

.css-14covj4-MuiToolbar-root {
  padding-left: 0px !important;
  padding-top: 20px;
}

/* Details Tiles */
.buttons-stack {
  flex-direction: row !important;
  justify-content: flex-start;
}

.order-tile {
  padding: 15px !important;
  background-color: #F8F8F8 !important;
}

.order-tile-subtitle {
  padding: 15px 0px !important;
}

.excluded-shows-list:hover {
  opacity: 0.8;
  cursor: pointer;
}

.accordion-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px !important;
}

.MuiInputBase-input {
  min-height: 56px;
}

.MuiFormControl-root {
  width: 300px;
}

.MuiFormControl-root.MuiDataGrid-filterFormDeleteIcon {
  width: 30px;
}

.MuiInputBase-input.MuiSelect-select {
  height: 0px;
}

.flex-basis-0 {
  flex-basis: 0;
}

.event-media {
  padding: 10px;
}

/* Filtering Section */
.filters {
  padding: 20px;
}

.filter-section {
  padding: 10px 0px 20px 0px;
}

.search-checkbox.admin-flag-checkbox,
.search-checkbox.invalid-order-checkbox {
  margin-left: 10px;
}

/* Buttons */
.page-buttons {
  display: flex;
  justify-content: flex-end;
}

.button {
  font-family: monospace;
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  margin: 10px 10px 15px 0 !important;
}

.save-button {
  padding: 20px 0px;
}

@media (max-width: 800px) {
  /* Mobile and Desktop Rendering */
  .showmobile {
    display: block;
  }

  .stack.showmobile,
  .css-e53awj-MuiStack-root.showmobile {
    display: flex;
  }

  .stack.hidemobile,
  .css-e53awj-MuiStack-root.hidemobile,
  .hidemobile {
    display: none;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .page-title {
    font-size: 30px !important;
  }

  .buttons-stack {
    flex-direction: column !important;
    justify-content: stretch;
  }

  .button {
    margin: 10px 0px !important;
  }

}
